import React from 'react'
import Seo from '../../components/Seo'
import BigTitle from '../../components/BigTitle'
import DefaultSection from '../../components/DefaultSection'
import Article from '../../components/Article'
import IconTeaser from '../../components/IconTeaser'
import NavigationLink from '../../components/NavigationLink'
import IndividualContactSection from '../../components/IndividualContactSection'
import Image from '../../components/Image'
import Card from '../../components/Card'
import CardDeck from '../../components/CardDeck'

const Page = () => {
  return (
    <React.Fragment>
      <Seo title="Referenz: Interdiscount - Division der Coop Genossenschaft"
           description="Erfahren Sie, wie wir gemeinsam mit Interdiscount eine hochgradig innovagtive Platform für das Produktmanagement geschaffen haben."
      />

      <BigTitle
        title="Durch gezieltes Produktmanagement zu einem gestärkten Kauferlebnis"
        imageRelativePath={'Titelbild_CaseStudy-Probot.jpg'}
        backgroundColor="#fff"
        className="mask-black"
        textInverted={true}
        menuInverted={true}
        logoInverted={true}
      >
        Case Study - Interdiscount
      </BigTitle>
      <DefaultSection className="pt-5 pt-lg-0">
        <Article>
          <p>
            Die Produktpalette von Interdiscount wächst und stellt sich dabei täglich der Wettbewerbssituation im
            Online-Handel. Ein weiterer Online-Shop ist für den Kunden immer nur einen Klick entfernt. Für
            Interdiscount ist es entscheidend, ein Softwareportfolio zu nutzen, das die Verwaltungsprozesse zielführend
            optimiert und unterstützt, um damit sowohl die eigene Marge, als auch die Zufriedenheit der Kunden stetig
            auf einem hohen Niveau zu halten.
          </p>
          <h2>Prozessoptimierung und erhöhte Performance durch PROBOT</h2>
          <p>
            Eine leicht verständliche, bedarfsgerechte und vor allem auf die Bedürfnisse von Interdiscount
            zugeschnittene System-Oberfläche sollte den Mitarbeitern zukünftig als Ausgangsbasis für das Erreichen der
            Ziele dienen. Ein Proof-of-Concept mit allen Ideen, Anforderungen und Konzepten war die Ausgangsbasis für
            eine Neuentwicklung des Produktdatenmanagements. Mit dem Projekt PROBOT sollten Agilität, Schnelligkeit,
            Automatisierung, Performance-Verbesserung sowie eine Qualitätserhöhung im Fokus stehen.
          </p>
        </Article>
        <CardDeck className="mt-5 mb-3" cardsPerRow={2}>
          <Card>
            <div className="text-center text-md-left">
              <h2>Technologien</h2>
              <p className="mt-5">
                Spring Boot, Angular, Kafka, ELK-Stack, Kubernetes, Docker, Microservices, Cloud, Machine Learning
              </p>
            </div>
          </Card>
          <Card>
            <div className="text-center text-md-left">
              <h2>Branche</h2>
              <p className="mt-5">
                Retail, E-Commerce
              </p>
            </div>
          </Card>
        </CardDeck>
        <Article>
          <h2>Die Challenge</h2>
          <p>
            Für die wachsende Anzahl an Artikeln im Online-Geschäft ist ein allumfassendes Datenpaket aus
            Produkt-Beschreibungen, Produkt-Bildern und weiteren technischen Spezifikationen notwendig. Eine
            strukturierte Datenbasis ist für Interdiscount auf Grund der speziellen Anforderungen im ‪Bereich Heim- und
            Unterhaltungselektronik‬ mit ihren vielfältigen Variationen und Charakteristiken unerlässlich, da sich diese
            signifikant auf das positiv wahrgenommene Einkaufserlebnis der Kunden auswirkt. Wichtig und gar
            selbstverständlich sind für den Kunden Shop-Features wie z. B. Produktvergleiche, Filter, eine effiziente
            Suche und detaillierte Produktbeschreibungen. Wer kauft heute schon einen Mixer oder ein Notebook ohne die
            zahlreichen Varianten vergleichbarer Modelle im Vorfeld der Kaufentscheidung gegenübergestellt zu
            haben.
          </p>
          <p>
            Da anders als beispielsweise im Textil-Handel Produkte des Heimelektronik-Bereichs nicht homogen beschrieben
            werden können, wurde im Zuge stetiger Prozessoptimierungen die Notwendigkeit eines Ausbaus der
            Stammdatenverwaltung in den Fokus gerückt. Im Hauptaugenmerk lag die fehlende Gesamtübersicht der
            spezifischen Produktinformationen zu den einzelnen Artikeln. Diese aus Hersteller- sowie Händlerquellen und
            Datenprovidern gespeisten Daten lieferten keine einheitliche Anwendungsfläche zur Datenaufbereitung.
          </p>
          <p>
            Die Folge? Ein zeitintensiver, komplizierter und aufwendiger Artikel-Pflegeprozess, der zu diesem Zeitpunkt
            hauptsächlich manuell durch ein großes Team abgearbeitet wurde. BUSCHMAIS stand vor der Aufgabe eine moderne
            und für die Zukunft ausgerichtete Stammdatenverwaltung unter Einsatz aktueller Technologien für den
            Online-Shop von Interdiscount zu entwickeln und in die vorhandenen Unternehmensabläufe zu integrieren.
          </p>
        </Article>
      </DefaultSection>
      <DefaultSection className="bg-level-1">
        <IconTeaser imageRelativePath={'Logo_Interdiscount.png'} iconImageAlt="Logo Interdiscount">
          <p className="text-black-50">
            Interdiscount ist die Nummer 1 für Heim- und Unterhaltungselektronik in der Schweiz. Mit den rund 180
            Filialen und einem attraktiven Onlineshop ist Interdiscount überall für seine Kunden da. Bei Interdiscount
            findet man die besten Brands, attraktivste Preise, kompetente Beratung, eine riesige Auswahl und ein
            Top-Service. Als führender Cross-Channel Händler der Schweiz können Onlinebestellungen bis 20 Uhr bereits
            am nächsten Tag in der gewünschten Filiale abgeholt oder zu Hause entgegengenommen werden. Interdiscount –
            einkaufen wie es dir gefällt.
          </p>
        </IconTeaser>
      </DefaultSection>
      <DefaultSection>
        <Article>
          <h2>Die Vision</h2>
          <p>
            Eine leicht verständliche, bedarfsgerechte und vor allem auf die Bedürfnisse von Interdiscount
            zugeschnittene System-Oberfläche sollte den Mitarbeitern zukünftig als Ausgangsbasis für das Erreichen der
            Ziele dienen. Ein Proof-of-Concept mit allen Ideen, Anforderungen und Konzepten war die Ausgangsbasis für
            eine Neuentwicklung des Produktdatenmanagements. Mit dem Projekt PROBOT sollten Agilität, Schnelligkeit,
            Automatisierung, Performance-Verbesserung sowie eine Qualitätserhöhung im Fokus stehen.
          </p>
          <h2>Die Vorgehensweise</h2>
          <p>
            Interdiscount und BUSCHMAIS analysierten gemeinsam die betroffenen Geschäftsprozesse sowie das bestehende
            System und deren Abbildung innerhalb der unternehmensinternen IT-Systemlandschaft. Die so geplante
            Stammdatenplattform syndiziert Daten aus anderen Systemen und generiert durchgehend einheitliche Datensätze
            für das Produktmanagement und steigert durch diese Vernetzung verschiedener IT-Systeme die Effizienz.
          </p>
          <p>
            Ein sich flexibel in die Prozess- und Anwendungslandschaft von Interdiscount integrierendes
            Standardsoftwareprodukt war am Markt nicht verfügbar. Die Entscheidung eine individuell auf die
            Kundenbedürfnisse zugeschnittene flexible Anwendung zu entwickeln wurde in enger Abstimmung von BUSCHMAIS
            mit Interdiscount getroffen.
          </p>
          <p>
            Um den extrem kurzzyklischen Anforderungen des Time-to-Market Prinzips im
            Online-Elektronikhandel gerecht zu werden, wurde das Projekt von Anfang an durch ein agiles, iteratives
            Vorgehensmodell aufgesetzt. Die Umsetzung erfolgte in kurzen Zyklen innerhalb von zwei Projektphasen.
            Wesentliche Prozessoptimierungen innerhalb der Phasen waren die Reduktion der zur Stammdatenpflege
            notwendigen Anwendungssysteme; die Verringerung des manuellen Anteils bei der Datenpflege, verbunden mit der
            Ablösung von unübersichtlichen Verwaltungsstrukturen sowie der Aufbau einer Management-Perspektive, um
            administrative Vorgänge zu erleichtern.
          </p>
          <p>
            <Image relativePath={'Case-Study-Probot-Abb1_Artikelpflege.png'}
                   alt="Mit PROBOT befindet sich Interdiscount auf dem Weg zur vollautomatisierten Artikelpflege. "
                   caption="Mit PROBOT befindet sich Interdiscount auf dem Weg zur vollautomatisierten Artikelpflege. " />
          </p>
          <p>
            In der ersten Phase erfolgte die Bereitstellung einer zentralen
            Instanz zur Suche von Artikeldaten für den manuellen Pflegeprozess. Diese Lösung ermöglichte von Beginn an
            die Pflege von Abbildungsvorschriften als Grundlage einer automatisierten Datenübernahme für ganze
            Artikelkategorien. Das Ziel der ersten Phase war es zudem, durch das frühzeitige Anwenden von
            Abbildungsvorschriften langfristig erste Erfahrungen zu sammeln um eine grundlegende Datenbasis für die
            späteren Ausbaustufen der Software zu etablieren. Die zweite Phase widmete sich der automatisierten
            Übernahme der Artikelstammdaten. Ferner werden Aktualisierungen bei den Datenprovidern automatisch im
            gesamten Datenbestand eingepflegt.
          </p>

          <h2>Die Lösung</h2>
          <p>
            Der durch BUSCHMAIS initiierte agile Projektansatz machte es bereits nach kurzer Zeit möglich, mit der
            schrittweisen Einführung der Softwarelösung zu beginnen. Ein User Centered Design hebt Potentiale und
            Bedürfnisse von Interdiscount in den Vordergrund. Transparenz und Flexibilität innerhalb des Projektes
            führten zur Risikominimierung, zudem birgt das iterative Vorgehen die Möglichkeit, jederzeit auf künftige
            oder geänderte Anforderungen einzugehen. Die Einführung von Software, lange vor Ende des Gesamtprojekts,
            schafft eine erhöhte Produktivität, verkürzt die Zeitintervalle der Time-to-Market-Strategie und generiert
            den versprochenen echten Wettbewerbsvorteil für Interdiscount.
          </p>
          <p>
            <Image relativePath={'Case-Study-Probot-Abb2_Datenproviderintegration.png'}
                   alt="Mit der Neuentwicklung von PROBOT ist es möglich, eine Vielzahl an Datenprovidern sowie spezielle Datenformate in kürzester Zeit zu integrieren."
                   caption="Mit der Neuentwicklung von PROBOT ist es möglich, eine Vielzahl an Datenprovidern sowie spezielle Datenformate in kürzester Zeit zu integrieren." />
          </p>
          <h2>Das Ergebnis</h2>
          <p>
            Die durch BUSCHMAIS realisierte Plattform erhöht mit ihrem verständlichen und
            übersichtlichen Interfacedesign die Variablen User Availability und Usability und ermöglicht Interdiscount
            einen gesteigerten Geschäftsnutzen durch eine effizientere und zeitsparendere Arbeitsweise.
            Die technisch raffinierte Lösung zur Prozessoptimierung des Produktmanagements liefert Interdiscount die
            Möglichkeit einer stetig wachsenden Anzahl an Artikeln, eine deutlich verkürzte Time-to-Market Dauer, sowie
            eine entscheidende Verbesserung in den Bereichen Flexibilität, Übersichtlichkeit und Controlling.
          </p>
          <blockquote className="text-brand">
            <p>
              Durch das Projekt PROBOT konnte der Ressourceneinsatz optimiert werden. So ist es nun möglich, das
              vorhandene Personal dort einzusetzen, wo es am meisten für unsere Kunden bewirken kann.<br /><em
              className="text-muted"><small> Moritz Dietze, Leiter Stammdatenmanagement, Interdiscount, Jegenstorf,
              Schweiz</small></em>
            </p>
          </blockquote>
          <p>
            BUSCHMAIS hat mit der zuverlässigen und schnellen Artikelverwaltung die Grundlage geliefert, so dass
            Interdiscount
            seinen Kunden einen zuverlässigen Service bieten kann und seinen Schweizer Mitbewerbern weiterhin voraus
            ist.
          </p>
          <p>
            <Image relativePath={'Case-Study-Probot-Abb3_Zeitaufwand.png'}
                   alt="Der Pflegeaufwand pro Artikel sinkt mit dem Einsatz von PROBOT um mehr als 70%."
                   caption="Der Pflegeaufwand pro Artikel sinkt mit dem Einsatz von PROBOT um mehr als 70%." />
          </p>
          <p>
            Mit einer Quantitätssteigerung der Artikelpflege um den Faktor 4,3 bringt die durch BUSCHMAIS
            implementierte Anwendung sehr deutliche Effizienzvorteile gegenüber der bisherigen Lösung. Mit dem Abbau
            technologischer Altlasten sollen auch zukünftig weitere Geschäftsprozesse flexibel und nachhaltig
            umgestaltet werden, um den individuellen Bedürfnissen von Interdiscount in der internen IT-Systemlandschaft
            gerecht zu werden und den zukünftigen technologischen und marktbezogenen Veränderungen gewachsen zu sein.
          </p>
        </Article>
      </DefaultSection>
      <DefaultSection>
        <Article>
          <p>
            <NavigationLink to="/download/Case-Study_Interdiscount_Probot.pdf" asTextLink
                            ariaLabel="Stark im Wettbewerb dank einem gezielten Produktmanagement bei Interdiscount">Download
              (PDF)</NavigationLink>
            <br /><small className="text-muted">Stand: 2020</small>
          </p>
        </Article>
      </DefaultSection>
      <IndividualContactSection personKey={'tobias'} />
    </React.Fragment>
  )
}

export default Page
